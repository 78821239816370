import { Component, Inject, Vue } from "vue-property-decorator";
import { CONTAINER_TYPES } from "../../../../infrastructure/ioc/container-types";
import ServiceInterface from "@/vue/service/service-interface";
import DfBrand from "@/vue/domain/brand/df-brand";
import Utils from "@/vue/infrastructure/utils/helpers";
import DfStore from "@/vue/domain/store/df-store";

@Component
export default class DfFooterComponent extends Vue {
  @Inject(CONTAINER_TYPES.ServiceInterface)
  private service!: ServiceInterface;

  get currentStore(): DfStore {
    return this.$store.getters.currentStore;
  }

  get brand(): DfBrand {
    return this.$store.getters.brand;
  }

  get selexFooter(): string {
    return Utils.getPropertyValue(this.brand, Utils.PROPERTY_SELEX_FOOTER, "TEXT");
  }
}
