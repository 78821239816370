import { Component, Inject, Vue, Watch } from "vue-property-decorator";
import { CONTAINER_TYPES } from "../../../../infrastructure/ioc/container-types";
import ServiceInterface from "@/vue/service/service-interface";
import DfStore from "@/vue/domain/store/df-store";
import Utils from "@/vue/infrastructure/utils/helpers";
import DfBrand from "@/vue/domain/brand/df-brand";
import DfShoppingProduct from "@/vue/domain/shopping-product/df-shopping-product";
import vuescroll from "vuescroll";
import Swal from "sweetalert2";
import { Printd } from "printd";
import moment from "moment";
import DfUser from "@/vue/domain/user/df-user";

const DfModalComponent = () => import(/* webpackChunkName: 'df-modal' */ "@/vue/components/df-modal/df-modal.vue");

const DfStoreCardComponent = () => Utils.externalComponent2("df-store-card");
const DfPushCardComponent = () => Utils.externalComponent2("df-push-card");
const DfSectionLinksComponent = () => Utils.externalComponent2("df-section-links");

@Component({
  components: {
    DfModal: DfModalComponent,
    DfStoreCard: DfStoreCardComponent,
    DfPushCard: DfPushCardComponent,
    DfSectionLinks: DfSectionLinksComponent,
    DfPrivateArea: () => Utils.externalComponent2("df-private-area"),
    vuescroll,
  },
  filters: {
    dateFormat(value: Date | string): string {
      return value != null ? moment(value).format(Utils.DATE_STRING_PATTER) : "";
    },
  },
})
export default class DfHeaderComponent extends Vue {
  @Inject(CONTAINER_TYPES.ServiceInterface)
  private service!: ServiceInterface;

  private tempValue: string = null;
  private logoRules: any = {
    famila: ["arca/famila", "maxidi/famila", "unicomm/famila"],
    aeo: ["maxidi/aeo"],
    emi: ["gmf/emi"],
    emisfero: ["unicomm/emisfero"],
    galassia: ["maxidi/galassia"],
    mercato: [],
    pan: ["superemme/pan"],
    gulliver: ["alfi/gulliver"],
  };

  get brand(): DfBrand {
    return this.$store.getters.brand;
  }

  get currentStore(): DfStore {
    return this.$store.getters.currentStore;
  }

  get selexPrivateAreaUrl(): string {
    return Utils.getPropertyValue(this.brand, Utils.PROPERTY_SELEX_PRIVATE_AREA_URL, "STRING", "");
  }

  get selexAwardsUrl(): string {
    const selexAwardsUrl: string = Utils.getPropertyValue(this.brand, Utils.PROPERTY_SELEX_AWARDS_URL, "STRING", "");
    const selexStoreAlias: string = Utils.getPropertyValue(this.currentStore, Utils.PROPERTY_SELEX_STORE_ALIAS, "STRING");
    return selexStoreAlias ? `${selexAwardsUrl}${selexStoreAlias}/catalogo-premi` : selexAwardsUrl;

    return Utils.getPropertyValue(this.brand, Utils.PROPERTY_SELEX_AWARDS_URL, "STRING", "").replace("$store-alias", this.currentStore.alias);
  }

  get selexLogoHeader(): string {
    return Utils.getPropertyImageUrl(this.brand, Utils.PROPERTY_SELEX_LOGO_HEADER, null);
  }

  get selexLogoImageUrl(): string {
    return this.selexLogoHeader ? this.selexLogoHeader : "";
  }

  get selexHomeUrl(): string {
    return Utils.getPropertyValue(this.brand, Utils.PROPERTY_SELEX_HOME_URL, "STRING");
  }

  get selexStoreUrl(): string {
    const selexStoreUrl: string = Utils.getPropertyValue(this.brand, Utils.PROPERTY_SELEX_STORE_URL, "STRING", "");
    const selexStoreAlias: string = Utils.getPropertyValue(this.currentStore, Utils.PROPERTY_SELEX_STORE_ALIAS, "STRING");
    return selexStoreAlias ? `${selexStoreUrl}${selexStoreAlias}/offerte-volantino` : selexStoreUrl;
  }

  get selexStoresUrl(): string {
    return Utils.getPropertyValue(this.brand, Utils.PROPERTY_SELEX_STORES_URL, "STRING");
  }

  get selexMallsUrl(): string {
    return Utils.getPropertyValue(this.brand, Utils.PROPERTY_SELEX_MALLS_URL, "STRING");
  }

  get selexHeaderColor(): string {
    return Utils.getPropertyValue(this.brand, Utils.PROPERTY_SELEX_HEADER_COLOR, "STRING", "black");
  }

  get shoppingList(): Array<DfShoppingProduct> {
    return this.$store.getters.shoppingList;
  }

  set shoppingList(shoppingList: Array<DfShoppingProduct>) {
    this.$store.dispatch("setShoppingList", shoppingList);
  }

  get copyShoppingListValue() {
    let bodyContent = `${this.$t("df-card-shopping-list.label")} - ${this.$options.filters!.dateFormat(Date.now())}\n\n`;
    [...this.shoppingList].reverse().forEach((product) => {
      bodyContent += `${product.description}\n\n`;
    });
    return bodyContent;
  }

  get messengerShoppingList(): string {
    let bodyContent = `${this.$t("df-card-shopping-list.label")} - ${this.$options.filters!.dateFormat(Date.now())}\n\n`;
    [...this.shoppingList].reverse().forEach((product) => {
      bodyContent += `${product.description}\n\n`;
    });
    return `https://m.me/?text=${encodeURIComponent(bodyContent)}`;
  }

  get whatsappShoppingList(): string {
    let bodyContent = `${this.$t("df-card-shopping-list.label")} - ${this.$options.filters!.dateFormat(Date.now())}\n\n`;
    [...this.shoppingList].reverse().forEach((product) => {
      bodyContent += `${product.description}\n\n`;
    });
    return `https://wa.me/?text=${encodeURIComponent(bodyContent)}`;
  }

  get emailShoppingList(): string {
    const subjectContent = `${this.$t("df-card-shopping-list.label")} - ${this.$options.filters!.dateFormat(Date.now())}`;
    let bodyContent = ``;
    [...this.shoppingList].reverse().forEach((product) => {
      bodyContent += `${product.description}\n\n`;
    });
    return `mailto:?subject=${subjectContent}&body=${encodeURIComponent(bodyContent)}`;
  }

  get stores(): Array<DfStore> {
    return this.$store.getters.stores;
  }

  get storesLabel(): string {
    return Utils.getBasePath() === "/selex/emisfero" ? "Ipermercati" : "Punti vendita";
  }

  get showLogin(): boolean {
    return this.$store.getters.showLogin;
  }

  get enablePrivateArea(): boolean {
    const rule01: boolean = Utils.getPropertyValue(this.currentStore, Utils.PROPERTY_PRIVATE_AREA_DISABLED, "BOOLEAN", false);
    const rule02: boolean = Utils.enablePrivateArea();
    return !rule01 && rule02;
  }

  get user(): DfUser {
    return this.$store.getters.user;
  }

  get isUserLogged(): boolean {
    return !!this.user;
  }

  get brandIconPath(): string {
    return `${Utils.getPublicPath()}/assets/LogoMyGulli.svg`;
  }

  @Watch("showLogin")
  private showLoginChange() {
    if (<any>this.$refs.tippyPrivateAreaSelex && this.showLogin && (<any>this.$el).checkVisibility()) {
      this.$store.dispatch("setShowLogin", false);
      (<any>this.$refs.tippyPrivateAreaSelex).tippy().show();
    }
  }

  beforeMount() {
    const shoppingList: any = Utils.localStorageGetItem("df-shopping-cart");
    if (shoppingList) {
      this.$root.$emit("checkCart", JSON.parse(shoppingList));
    }
  }

  private toggleSelection(shoppingProduct: DfShoppingProduct) {
    this.shoppingList = this.shoppingList.map((product: DfShoppingProduct) => {
      product.checked = product.description === shoppingProduct.description ? !product.checked : product.checked;
      return product;
    });
  }

  private removeFromList(shoppingProduct: DfShoppingProduct) {
    const index: number = this.shoppingList.findIndex((product: DfShoppingProduct) => product.description === shoppingProduct.description);
    if (index !== -1) {
      this.shoppingList = this.shoppingList.slice(0, index).concat(this.shoppingList.slice(index + 1, this.shoppingList.length));
    }
  }

  private addToList() {
    if (this.tempValue) {
      this.shoppingList = [new DfShoppingProduct(this.tempValue, false), ...this.shoppingList];
      Utils.setAnaliticsTraker({
        event: "add_product_to_shopping_list",
        origin: "smt-digitalflyer-ui",
        productAlias: "",
        productBrand: "",
        productCategory: "",
        productCode: "",
        productDescription: this.tempValue,
        productDiscountType: "",
        productFeatured: "",
        productInitialPrice: "",
        productPrice: "",
        productTitle: "",
        promotionAlias: "",
        promotionBeginDate: "",
        promotionCode: "",
        promotionDescription: "",
        promotionEndDate: "",
        storeAlias: this.currentStore.alias,
        storeCode: this.currentStore.code,
        storeName: this.currentStore.name,
      });
      this.tempValue = null;
    }
  }

  private removeShoppingList() {
    Swal.fire({
      title: this.$t("df-card-shopping-list.delete.title").toString(),
      text: this.$t("df-card-shopping-list.delete.body").toString(),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Continua",
    }).then((result) => {
      if (result.value) {
        this.shoppingList = [];
      }
    });
  }

  private onClipboardSuccess(data: any) {
    const icon = data.event.target;
    const button = data.event.target.parentNode;
    if (icon != null) {
      icon.classList.toggle("far");
      icon.classList.toggle("fa-copy");
      icon.classList.toggle("fas");
      icon.classList.toggle("fa-check-double");
      button.setAttribute("title", "Copiato");
      setTimeout(() => {
        icon.classList.toggle("far");
        icon.classList.toggle("fa-copy");
        icon.classList.toggle("fas");
        icon.classList.toggle("fa-check-double");
        button.setAttribute("title", "Copia");
      }, 2000);
    }
    data.event.preventDefault();
  }

  private printShoppingList() {
    const d = new Printd();
    const cssText = `
      .df-shopping-list-item-check,
      button {
        display: none;
      }

      .df-shopping-list-item {
        margin-bottom: 1rem;
      }
    `;
    d.print(document.getElementById("printable-area-shopping-list"), [cssText]);
  }

  private openUrl(url: string) {
    if (url) {
      window.open(url, "_self");
    }
  }

  private showMyLogo(myLogoName: string): boolean {
    return (this.logoRules[myLogoName] || []).includes(Utils.getApiPath());
  }

  private close() {
    if (<any>this.$refs.tippyPrivateAreaSelex) {
      (<any>this.$refs.tippyPrivateAreaSelex).tippy().hide();
    }
  }

  private trackShoppingInfo() {
    Utils.setAnaliticsTraker({
      event: "show_shoppinglist",
      origin: "smt-digitalflyer-ui",
      storeAlias: this.currentStore.alias,
      storeCode: this.currentStore.code,
      storeName: this.currentStore.name,
    });
  }
}
